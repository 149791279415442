import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Cookies from 'js-cookie';

import GlobalStyle from './globalStyles';

import AgeConfirm from './AgeConfirm/AgeConfirm';

const App = ({ children }) => {
  const ageConfirm = Cookies.get('ageConfirm');
  const [isShowAgeConfirm, setIsShowAgeConfirm] = useState(false);
  const [isRunAnimation, setIsRunAnimation] = useState(false);

  const helmetContext = {};

  useEffect(() => {
    if (ageConfirm !== 'confirmed') setIsShowAgeConfirm(true);
  }, [ageConfirm]);

  const handleHideAgeConfirm = () => {
    Cookies.set('ageConfirm', 'confirmed', {
      path: '/',
      secure: true,
      expires: 365,
      domain: process.env.GATSBY_FRONTEND_DOMAIN || 'localhost',
    });

    setIsRunAnimation(prevState => !prevState);

    setTimeout(() => {
      setIsShowAgeConfirm(false);
    }, 75);
  };

  return (
      <GoogleReCaptchaProvider reCaptchaKey="6LeekO8aAAAAABeMk2rnJFtG0moix0LJ0BKXjDjJ">
        <GlobalStyle />
        {children}
        {isShowAgeConfirm && (
          <AgeConfirm
            isShow={isShowAgeConfirm}
            isHide={isRunAnimation}
            onConfirm={handleHideAgeConfirm}
          />
        )}
      </GoogleReCaptchaProvider>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
