import React, { useEffect, useRef, useState } from "react";
import { Portal } from "react-portal";

import {
  Root,
  Dialog,
  Content,
  Body,
  Logo,
  Text,
  Label,
  Form,
  Select,
  Submit,
  SelectContainer,
  Legend,
  FieldSet,
  Option,
} from "./AgeConfirm.style";

import FocusLock from 'react-focus-lock';

import LogoSrc from "../../images/logo-gold.svg";

import useLockBodyScroll from "../../hooks/useLockBodyScroll";

const AgeConfirm = ({ onConfirm, isShow, isHide }) => {
  useLockBodyScroll();
  const [formValues, setFormValues] = useState({});
  const [isInvalid, setIsInvalid] = useState(false);

  const dialogRef = useRef(null);

  useEffect(() => {
    if (isShow && dialogRef.current) {
      dialogRef.current.focus();
    }
  }, [isShow]);

  const months = [
    { value: "January" },
    { value: "February" },
    { value: "March" },
    { value: "April" },
    { value: "May" },
    { value: "June" },
    { value: "July" },
    { value: "August" },
    { value: "September" },
    { value: "October" },
    { value: "November" },
    { value: "December" },
  ];

  const years = () => {
    const date = new Date();
    let options = [];
    for (let i = 1930; i <= date.getFullYear(); i++) {
      options.push(i);
    }
    return options.map((option) => (
      <Option key={option} value={option}>
        {option}
      </Option>
    )).slice().reverse();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormValues = {
      ...formValues,
      [name]: value,
    };
    console.info({newFormValues});
    setFormValues(newFormValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.info({formValues});
    if(['', null, undefined].includes(formValues?.year) || ['', null, undefined].includes(formValues?.month) || ['', null, undefined].includes(formValues?.day)) {
      return setIsInvalid(true);
    }
    setIsInvalid(false);
    const age = Math.floor(
      (new Date() -
        new Date(
          `${formValues.year}-${formValues.month}-${formValues.day}`
        ).getTime()) /
        3.15576e10
    );
    if (age >= 21) {
      onConfirm()
    } else {
      // alert(age + " -- " + `${formValues.year}-${formValues.month}-${formValues.day}`)
      window.location.href = "https://www.responsibility.org/";
    };
  };

  return (
    <Portal>
      <FocusLock>
      <Root
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
        $isShow={isShow}
        $isHide={isHide}
        aria-label="Age Confirmation Modal"
      >
        <Dialog ref={dialogRef} tabIndex={-1}>
          <Content>
            <Body>
              <h1 id="heading">
              <Logo src={LogoSrc} alt="James Ownby Reserve logo" />
              </h1>
              <Text>
                Can we some see some ID please?
                <br />
                It’s part of our commitment to responsible drinking.
              </Text>
              
              <Form onSubmit={handleSubmit}>

              <FieldSet>
                <Legend>ENTER YOUR BIRTHDATE</Legend>
                {isInvalid && <p style={{color: '#63656a', position: 'absolute', top: '-24px' }}>Invalid birthdate.</p>}
                <div style={{ width: '100%' }}>
                  <Select
                    name="month"
                    value={formValues.month}
                    onChange={handleChange}
                    aria-label="Month"
                  >
                    <Option value="">Month</Option>
                    {months.map((month, idx) => (
                      <Option key={month.value} value={`${idx + 1}`.padStart(2, '0')}>
                        {month.value}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    name="day"
                    value={formValues.day}
                    onChange={handleChange}
                    aria-label="Day"
                  >
                    <Option value="">Day</Option>
                    {Array.from({ length: 31 }, (_, i) => (
                      <Option key={i + 1} value={`${i + 1}`.padStart(2, '0')}>
                        {i + 1}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    name="year"
                    value={formValues.year}
                    onChange={handleChange}
                    aria-label="Year"
                  >
                    <Option value="">Year</Option>
                    {years()}
                  </Select>
                </div>
                </FieldSet>
                <Submit type="submit">COME IN</Submit>
              </Form>
            </Body>
          </Content>
        </Dialog>
      </Root>
      </FocusLock>
    </Portal>
  );
};

export default AgeConfirm;
